<template>
  <a-spin :spinning="loading" size="large">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="科 目" prop="subject">
        <a-select v-model="form.subject">
          <a-select-option v-for="item in scheduleOptions.subject" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="课时变更类型" prop="transactionSubCategory">
        <a-select v-model="form.transactionSubCategory">
          <a-select-option v-for="item in scheduleOptions.cuTransType" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="变更方式" prop="transactionType">
        <a-select v-model="form.transactionType">
          <a-select-option value="CREDIT">增加</a-select-option>
          <a-select-option value="DEBIT">减少</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item ref="transactionAmount" label="变更课时数" prop="transactionAmount">
        <a-input-number
          v-model="form.transactionAmount"
          style="width: 100%"
          placeholder="请输入变更课时数"
          @blur="
            () => {
              $refs.transactionAmount.onFieldBlur();
            }
          "
        />
      </a-form-model-item>
      <a-form-model-item label="上传截图" prop="timezone">
        <a-upload
          name="file"
          :file-list="fileList"
          list-type="picture-card"
          @change="handleChange"
          @preview="handlePreview"
        >
          <div v-if="fileList.length < 3">
            <a-icon :type="uploading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-model-item>
      <a-form-model-item label="备 注" prop="remark">
        <a-textarea v-model="form.remark" placeholder="请输入备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 10 }">
        <a-button type="primary" @click="onSubmit"> 提交 </a-button>
        <a-button style="margin-left: 10px" @click="resetForm"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>
<script>
import { postReq, putReq, getReq, loadScheduleOptions, loadTimezoneList, getCourse } from '@/api/schedule';
import { getDdlLabel } from '@/utils/util';
import storage from 'store';
import { imgpostUrl } from '@/api/register';
import { getUploadOssToken } from '@/api/headTeacher';
import BaseForm from '@/components/BaseForm/index.vue';

export default {
  extends: BaseForm,
  data() {
    return {
      getDdlLabel,
      labelCol: { span: 6 },
      wrapperCol: { span: 10 },
      previewVisible: false,
      previewImage: '',
      uploading: false,
      studentId: this.$route.query.id,
      loading: false,
      roles: [],
      userInfo: {},
      form: {
        studentId: this.$route.query.id,
        subject: null,
        transactionSubCategory: null,
        transactionType: null,
        transactionAmount: null,
        remark: null,
        data: {
          uploadPics: [],
        },
      },
      rules: {
        subject: [{ required: true, message: '请选择科目', trigger: 'blur' }],
        transactionSubCategory: [{ required: true, message: '请选择课时变更类型', trigger: 'change' }],
        transactionType: [{ required: true, message: '请选择变更方式', trigger: 'change' }],
        transactionAmount: [{ required: true, message: '请输入变更课时数', trigger: 'change' }],
      },
      apiUrl: '/api/admin/scheduling/course_unit',
      fileList: [],
      formData: null,
      uploading: false,
    };
  },
  created() {
    this.roles = storage.get('roles');
    this.userInfo = storage.get('userInfo');
    getCourse({ shelfStatus: ['ON'] }).then((courseOptions) => {
      this.courseOptions = courseOptions;
    });

    postReq('/api/account/ref/class_admin', {}).then((res) => {
      this.headmasterOptions = res.data.content;
    });
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
      } else {
        this.imgInfo = info;
        this.uploading = true;
        getUploadOssToken().then((res) => {
          this.uploadToOss(info, res.data.content);
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      imgpostUrl(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.fileList.push({
            uid: res.data.fileId,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
          console.log(this.fileList);
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let imgList = [];
          for (let item of this.fileList) {
            imgList.push({
              contentUrl: item.url,
              fileStorageId: item.uid,
            });
          }
          postReq(this.apiUrl, { ...this.form, data: { uploadPics: imgList } })
            .then((res) => {
              this.$message.success('提交成功!');
              history.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      history.go(-1);
    },
  },
};
</script>
