<template></template>
<script>
import { postReq, putReq, deleteReq, loadScheduleOptions } from '@/api/schedule';
import { getDdlLabel, extractOperator } from '@/utils/util';
const columns = [];
export default {
  data() {
    return {
      getDdlLabel,
      extractOperator,
      scheduleOptions: {
        subject: [],
        subjectType: [],
        courseType: [],
        standardType: [],
        feeType: [],
        classType: [],
        classStatus: [],
        scheduleStatus: [],
        stuScheduleStatus: [],
        stuScheduleStatusReason: [],
        timeSplitItemStatus: [],
        courseStructure: [],
        formClassCapacityStatus: [],
        workStatus: [],
        leaveStatus: [],
        attendanceStatus: [],
        workType: [],
        cuTransType: [],
        approveStatus: [],
        taskLogType: [],
        userRole: [],
        lossRefundType: [],
        lossRefundReason: {
          lossReasons: {},
          notRenewReasons: [],
          otherLossReasons: [],
          refundReasons: []
        },
        shelfStatus: [],
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      data: [],
      columns,
      loading: false,
      searchUrl: '',
      form: {},
    };
  },
  created() {
    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    resetForm(e) {
      e.preventDefault();
      this.traverseReset(this.form);
    },
    traverseReset(form) {
      for (let i in form) {
        if (!!form[i] && Object.prototype.toString.call(form[i]) === '[object Object]') {
          this.traverseReset(form[i]);
        } else {
          form[i] = undefined;
        }
      }
    },
  },
};
</script>
